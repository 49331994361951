import React, { Component } from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink } from 'react-router-dom';
import dayjs from 'dayjs';
import AppBar from '@mui/material/AppBar';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded';
import AdminPanelSettingsRoundedIcon from '@mui/icons-material/AdminPanelSettingsRounded';
import AlarmRoundedIcon from '@mui/icons-material/AlarmRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import AnalyticsRoundedIcon from '@mui/icons-material/AnalyticsRounded';
import PersonSearchRoundedIcon from '@mui/icons-material/PersonSearchRounded';
import Switch from '@mui/material/Switch';
import PhoneInTalkRoundedIcon from '@mui/icons-material/PhoneInTalkRounded';
import InsightsRoundedIcon from '@mui/icons-material/InsightsRounded';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import Slide from '@mui/material/Slide';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { polyfill } from 'es6-promise';
import axios from 'axios';
import { withAuth0 } from '@auth0/auth0-react';
import { io } from 'socket.io-client';
import isOnline from 'is-online';

import Landing from './com/landing.com';
import Analytics from './com/analytics.com';
import CustomerModal from './com/customermodal.com';
import CustomerSearch from './com/customersearch.com';
import Manage from './com/manage.com';
import Metrics from './com/metrics.com';
import UserSchedules from './com/schedules.com';
import Pulse from './com/pulse.com';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT,
  storageBucket: process.env.REACT_APP_FIREBASE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER,
  appId: process.env.REACT_APP_FIREBASE_APP,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

var api = axios.create({ withCredentials: true });

const socket = io();

var cancelToken;
var cancelNotifyToken;

const drawerWidth = 280;
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

polyfill();

function interval(func, wait, times) {
  var interv = function (w, t) {
    return function () {
      if (typeof t === "undefined" || t-- > 0) {
        setTimeout(interv, w);
        try {
          func.call(null);
        }
        catch (e) {
          t = 0;
          throw e.toString();
        }
      }
    };
  }(wait, times);

  setTimeout(interv, wait);
};

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeItem: 'analytics', loggedInUser: null, selected_shop: null, companies: null, multishop: false, dashboards: null,
      modal: false, customer: null, customer_shop: null, customer_system: null,
      mobileOpen: false, dbOpen: false, wbOpen: false, userMenu: false, notify: null, online: dayjs(), tosModal: false, errorNotify: { _id: '', type: '', value: '', status: false }
    };
  }

  /////////////////////

  closeSnackBar() {
    this.setState({ errorNotify: { _id: '', type: '', value: '', status: false } });
  }

  /////////////////////

  handleUserMenu(val) {
    this.setState({ userMenu: val });
  }

  /////////////////////

  handleDrawerToggle() {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  }

  /////////////////////

  handleMultishopChange = (data) => {
    this.setState({ multishop: data });
  };

  /////////////////////

  handleNotifyChange = (data) => {
    if ("Notification" in window) {
      if (Notification.permission !== 'granted' && data) {
        Notification.requestPermission().then((val) => {
          if (val === 'granted') {
            this.setState({ notify: data }, () => {
              toast.dismiss();
              toast.success("Notifications Enabled", {
                position: 'bottom-left'
              });
            });
          } else if (val === 'denied') {
            toast.error("You've Denied Notifications", {
              position: 'bottom-left'
            });
          } else {
            toast.warning('Please Allow Notifications', {
              position: 'bottom-left'
            });
          }
        });
      } else {
        this.setState({ notify: data }, () => {
          if (data) {
            toast.dismiss();
            toast.success("Notifications Enabled", {
              position: 'bottom-left'
            });
          } else {
            toast.success("Notifications Disabled", {
              position: 'bottom-left'
            });
          }
        });
      }
    } else {
      toast.error("Browser Not Compatible", {
        position: 'bottom-left'
      });
    }
  };

  /////////////////////

  handleModal(value) {
    this.setState({ modal: value }, () => {
      if (this.state.modal === false) {
        this.setState({ customer: null });
      }
    });
  }

  /////////////////////

  showCustomer() {
    if (this.state.customer) {
      return <CustomerModal shop_id={this.state.customer_shop} system={this.state.customer_system} customer={this.state.customer} loggedInUser={this.state.loggedInUser} shop={this.state.selected_shop} />
    } else {
      return "No Customer Found";
    }
  }

  /////////////////////

  handleUserCompanyChange(value) {
    var sc = null;
    this.state.loggedInUser.companies.forEach((company) => {
      if (company._id === value) {
        sc = company;
      }
    });
    this.setState({ selected_shop: sc }, async () => {
      toast.dismiss();
      this.state.loggedInUser.companies.forEach((shop) => {
        socket.emit('leave', shop.system + '_' + shop.id);
      });
      socket.emit('join', this.state.selected_shop.system + '_' + this.state.selected_shop.id);
    });
  }

  /////////////////////

  getNotify() {
    if (typeof cancelNotifyToken != typeof undefined) {
      cancelNotifyToken.cancel("canceled");
    }

    cancelNotifyToken = axios.CancelToken.source();

    this.props.auth0.getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0AUDIENCE,
      scope: process.env.REACT_APP_AUTH0SCOPE
    }).then((token) => {
      api.get(process.env.REACT_APP_BASEURL + 'api/settings/get/error_notify', { cancelToken: cancelNotifyToken.token, headers: { Authorization: `Bearer ${token}` } }).then(response => {
        this.setState({ errorNotify: response.data });
      });
    });
  }

  /////////////////////

  getLoggedInUser() {
    if (this.props.auth0.isAuthenticated) {
      if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel("canceled");
      }

      cancelToken = axios.CancelToken.source();

      this.props.auth0.getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0AUDIENCE,
        scope: process.env.REACT_APP_AUTH0SCOPE
      }).then((token) => {
        api.get(process.env.REACT_APP_BASEURL + 'api/users/email/' + this.props.auth0.user.email, { cancelToken: cancelToken.token, headers: { Authorization: `Bearer ${token}` } }).then(response => {
          var tmp = response.data.companies;
          tmp.sort((a, b) => a.name.localeCompare(b.name));
          var selected_shop = tmp[0];
          var companies = [];
          tmp.forEach((company) => {
            if (tmp.filter(x => x.name === company.name).length > 1 && company.hasOwnProperty('nickname')) {
              companies.push({ key: company._id, value: company._id, text: company.name + ' (' + company.nickname + ')' });
            } else {
              companies.push({ key: company._id, value: company._id, text: company.name });
            }
            if (this.state.selected_shop !== null) {
              if (company._id === this.state.selected_shop._id) {
                selected_shop = company;
              }
            }
          });
          var tmpd = response.data.dashboards;
          tmpd.sort((a, b) => a.sortorder - b.sortorder);
          var dashboards = [];
          tmpd.forEach((dashboard) => {
            dashboards.push(dashboard);
          });
          this.setState({
            loggedInUser: response.data,
            selected_shop: selected_shop,
            companies: companies,
            dashboards: dashboards
          }, () => {
            socket.emit('join', this.state.loggedInUser._id);
            this.state.loggedInUser.companies.forEach((shop) => {
              socket.emit('leave', shop.system + '_' + shop.id);
            });
            socket.emit('join', this.state.selected_shop.system + '_' + this.state.selected_shop.id);
            if (this.state.loggedInUser.admin === true) {
              socket.emit('join', 'admin');
            } else {
              socket.emit('leave', 'admin');
            }
            this.getNotify();
          });
        });
      });
    }
  }

  /////////////////////

  tosAgree() {
    this.props.auth0.getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0AUDIENCE,
      scope: process.env.REACT_APP_AUTH0SCOPE
    }).then((token) => {
      api.get(
        process.env.REACT_APP_BASEURL + 'api/users/update/tos/' + this.state.loggedInUser._id, { headers: { Authorization: `Bearer ${token}` } }
      ).then((response) => {
        toast.success('Terms & Conditions Accepted');
      });
    });
  }

  /////////////////////

  customerPhoneCall(msg) {
    if (this.state.loggedInUser) {
      if (this.state.loggedInUser.advisor) {
        if (msg.status.code === 'Setup') {
          if (msg.customer.length > 0) {
            this.props.auth0.getAccessTokenSilently({
              audience: process.env.REACT_APP_AUTH0AUDIENCE,
              scope: process.env.REACT_APP_AUTH0SCOPE
            }).then((token) => {
              api.get(
                process.env.REACT_APP_BASEURL + 'api/charts/customer/' + msg.customer[0].shop_id + '/' + msg.customer[0].system + '/' + msg.customer[0].customer_id,
                { headers: { Authorization: `Bearer ${token}` } }
              ).then(response => {
                var customer = response.data.customer;

                var shopRankConfig = {
                  dts: new Date().getTime(),
                  0: {
                    ro_min: 6,
                    ro_max: 10000,
                    aro_min: 0,
                    aro_max: 100
                  },
                  1: {
                    ro_min: 0,
                    ro_max: 5,
                    aro_min: 0,
                    aro_max: 10000
                  },
                  2: {
                    ro_min: 6,
                    ro_max: 10000,
                    aro_min: 100,
                    aro_max: 10000
                  },
                  3: {
                    ro_min: 6,
                    ro_max: 10000,
                    aro_min: 250,
                    aro_max: 10000
                  },
                  4: {
                    ro_min: 6,
                    ro_max: 10000,
                    aro_min: 350,
                    aro_max: 10000
                  }
                };

                if (this.state.selected_shop.hasOwnProperty('rankConfig')) {
                  shopRankConfig = this.state.selected_shop.rankConfig;
                }

                api.get(
                  process.env.REACT_APP_BASEURL + 'api/charts/customer/get/' + msg.customer[0].customer_id + '/' + msg.customer[0].shop_id + '/' + msg.customer[0].system,
                  { headers: { Authorization: `Bearer ${token}` } }
                ).then(rresponse => {
                  var rank = null;
                  if (rresponse.data) {
                    rank = rresponse.data;
                  }

                  var src = 'customer_1.png';
                  var tooltip = 'Developing Customer';
                  if (rank !== null) {
                    if (rank.rank === 0) {
                      tooltip = "Don't Book";
                      src = 'customer_0.png';
                    } else if (rank.rank === 4) {
                      tooltip = "Best Customer";
                      src = 'customer_4.png';
                    }
                  }

                  if (
                    shopRankConfig[4].ro_min <= customer.total_ros && customer.total_ros <= shopRankConfig[4].ro_max
                    &&
                    shopRankConfig[4].aro_min <= parseInt(customer.total_sales / customer.total_ros) && parseInt(customer.total_sales / customer.total_ros) <= shopRankConfig[4].aro_max
                  ) {
                    src = 'customer_4.png';
                    tooltip = "Best Customer";
                  } else if (
                    shopRankConfig[3].ro_min <= customer.total_ros && customer.total_ros <= shopRankConfig[3].ro_max
                    &&
                    shopRankConfig[3].aro_min <= parseInt(customer.total_sales / customer.total_ros) && parseInt(customer.total_sales / customer.total_ros) <= shopRankConfig[3].aro_max
                  ) {
                    src = 'customer_3.png';
                    tooltip = "Better Customer";
                  } else if (
                    shopRankConfig[2].ro_min <= customer.total_ros && customer.total_ros <= shopRankConfig[2].ro_max
                    &&
                    shopRankConfig[2].aro_min <= parseInt(customer.total_sales / customer.total_ros) && parseInt(customer.total_sales / customer.total_ros) <= shopRankConfig[2].aro_max
                  ) {
                    src = 'customer_2.png';
                    tooltip = "Good Customer";
                  } else if (
                    shopRankConfig[1].ro_min <= customer.total_ros && customer.total_ros <= shopRankConfig[1].ro_max
                    &&
                    shopRankConfig[1].aro_min <= parseInt(customer.total_sales / customer.total_ros) && parseInt(customer.total_sales / customer.total_ros) <= shopRankConfig[1].aro_max
                  ) {
                    src = 'customer_1.png';
                    tooltip = "Developing Customer";
                  } else if (
                    shopRankConfig[0].ro_min <= customer.total_ros && customer.total_ros <= shopRankConfig[0].ro_max
                    &&
                    shopRankConfig[0].aro_min <= parseInt(customer.total_sales / customer.total_ros) && parseInt(customer.total_sales / customer.total_ros) <= shopRankConfig[0].aro_max
                  ) {
                    src = 'customer_0.png';
                    tooltip = "Don't Book";
                  }

                  if (this.state.notify) {
                    var options = {
                      body: `${msg.from.phoneNumber}, ${dayjs().format('hh:mm:ss A')}${'\n'}Total Sales : $${parseInt(customer.total_sales)} | ARO : $${parseInt(customer.total_sales / customer.total_ros)}${'\n'}${msg.customer[0].wip === 1 ? '*** RO IN PROGRESS ***' : 'No Open RO'}`,
                      icon: 'https://dcw98afuhr186.cloudfront.net/' + src
                    }
                    var notification = new Notification(`${msg.customer[0].fname} ${msg.customer[0].lname}`, options);
                    notification.onclick = (event) => {
                      event.preventDefault();
                      if (this.state.selected_shop.system === 'tekmetric') {
                        window.open(`https://shop.tekmetric.com/admin/shop/${this.state.selected_shop.id}/customers/${customer.customer_id}`, "_blank");
                      } else if (this.state.selected_shop.system === 'shopware') {
                        window.open(`https://${this.state.selected_shop.cname}.shop-ware.com/customers/${customer.customer_id}`, "_blank");
                      } else {
                        window.focus();
                        this.setState({ customer: null, customer_shop: null, customer_system: null }, () => {
                          this.setState({ customer: msg.customer[0].customer_id, customer_shop: msg.customer[0].shop_id, customer_system: msg.customer[0].system }, () => {
                            this.handleModal(true);
                          });
                        });
                      }
                    }

                    setTimeout(notification.close.bind(notification), 30000);
                  } else {
                    toast.info(`Call From ${msg.from.phoneNumber}\n${dayjs().format('hh:mm:ss A')}`, {
                      position: 'bottom-left',
                      toastId: msg.id,
                      icon: () => <img src={'https://dcw98afuhr186.cloudfront.net/' + src} style={{ height: '25px' }} />,
                      autoClose: false,
                      onClick: () => {
                        this.setState({ customer: null, customer_shop: null, customer_system: null }, () => {
                          this.setState({ customer: msg.customer[0].customer_id, customer_shop: msg.customer[0].shop_id, customer_system: msg.customer[0].system }, () => {
                            this.handleModal(true);
                          });
                        });
                      },
                      closeOnClick: false
                    });
                  }
                }).catch((rerror) => {
                  console.log(rerror.response.data);
                });
              }).catch((cerror) => {
                console.log(cerror.response.data);
              });
            }).catch((token_error) => {
              console.log(token_error);
            });
          } else if (!this.state.notify) {
            toast.warning(`Call From ${msg.from.phoneNumber}\n${dayjs().format('hh:mm:ss A')}`, {
              position: 'bottom-left',
              toastId: msg.id,
              autoClose: 5000
            });
          } else {
            var options = {
              body: `${msg.from.phoneNumber}, ${dayjs().format('hh:mm:ss A')}`,
              icon: 'https://dcw98afuhr186.cloudfront.net/cpicon.png'
            }
            var notification = new Notification(`NEW CUSTOMER`, options);

            setTimeout(notification.close.bind(notification), 30000);
          }
        }
        if (msg.status.code === 'Disconnected' && toast.isActive(msg.id)) {
          toast.dismiss(msg.id);
        }
      }
    }
  }

  /////////////////////

  displayDashboards() {
    return this.state.dashboards.map((dashboard, i) => {
      return (
        <ListItemButton
          sx={{ pl: 6 }}
          component={NavLink}
          to={i === 0 ? "/" : dashboard.path}
          selected={this.state.activeItem === dashboard.path}
          onClick={() => { this.setState({ activeItem: dashboard.path }, () => { this.handleDrawerToggle(); }); }}
        >
          <ListItemText primary={dashboard.title} />
        </ListItemButton>
      );
    });
  }

  /////////////////////

  displayRoutes() {
    return this.state.dashboards.map((dashboard, i) => {
      return (
        <Route exact path={i === 0 ? "/" : dashboard.path} element={<Analytics
          shop={this.state.selected_shop}
          dashboard={dashboard.dashboard}
          view={dashboard.view}
          socket={socket}
          multishop={this.state.multishop ? this.state.loggedInUser.companies : null}
        />} />
      );
    });
  }

  /////////////////////

  displayDrawer() {
    return (
      <>
        <Toolbar disableGutters={true} sx={{ textAlign: 'center', alignItems: 'center' }}>
          <Typography component='div' sx={{ textAlign: 'center', alignItems: 'center', flexGrow: 1 }} >
            <img src='images/cp_logo_long.png' height={'35px'} style={{ margin: 'auto', verticalAlign: 'middle' }} />
          </Typography>
        </Toolbar>
        <List dense={true}>
          <ListItem key='dashboards' disablePadding >
            <FormControl sx={{ marginLeft: 'auto', marginRight: 'auto', marginBottom: '10px', width: '260px' }} size="small">
              <Select
                id="simple-select"
                value={this.state.selected_shop._id}
                onChange={(e, data) => { this.handleUserCompanyChange(data.props.value) }}
              >
                {this.state.companies.map((company) => {
                  return <MenuItem value={company.value}>{company.text}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </ListItem>
          <ListItem />
          <Divider />
          <ListItem />
          <ListItem
            key='dashboards'
            disablePadding
            onClick={() => { this.setState({ dbOpen: !this.state.dbOpen }); }}
          >
            <ListItemButton>
              <ListItemIcon>
                <AnalyticsRoundedIcon />
              </ListItemIcon>
              <ListItemText primary='Shop Trends Pro' />
              {this.state.dbOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          </ListItem>
          <Collapse in={this.state.dbOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding dense={true}>
              {
                this.state.companies !== null
                  ?
                  this.state.companies.length > 1
                    ?
                    <ListItem sx={{ pl: 6 }}>
                      <ListItemText primary="Multi-Shop" />
                      <Switch
                        edge="end"
                        checked={this.state.multishop}
                        onChange={(e, data) => { this.handleMultishopChange(data); }}
                      />
                    </ListItem>
                    :
                    <></>
                  :
                  <></>
              }
              {this.displayDashboards()}
              <Divider />
            </List>
          </Collapse>
          <ListItem key='pulse' disablePadding>
            <ListItemButton
              component={NavLink}
              to={'/pulse'}
              selected={this.state.activeItem === 'pulse'}
              onClick={() => { this.setState({ activeItem: 'pulse' }, () => { this.handleDrawerToggle(); }); }}
            >
              <ListItemIcon>
                <InsightsRoundedIcon />
              </ListItemIcon>
              <ListItemText primary='AI Insights' />
            </ListItemButton>
          </ListItem>
          {
            this.state.loggedInUser.advisor
              ?
              <ListItem key='customersearch' disablePadding>
                <ListItemButton
                  component={NavLink}
                  to='/customersearch'
                  selected={this.state.activeItem === 'customersearch'}
                  onClick={() => { this.setState({ activeItem: 'customersearch' }, () => { this.handleDrawerToggle(); }); }}
                >
                  <ListItemIcon>
                    <PersonSearchRoundedIcon />
                  </ListItemIcon>
                  <ListItemText primary='Service Advisor Assistant' />
                </ListItemButton>
              </ListItem>
              :
              <></>
          }
          {
            this.state.loggedInUser.roles.includes('owner')
              ?
              <ListItem key='metrics' disablePadding>
                <ListItemButton
                  component={NavLink}
                  to='/metrics'
                  selected={this.state.activeItem === 'metrics'}
                  onClick={() => { this.setState({ activeItem: 'metrics' }, () => { this.handleDrawerToggle(); }); }}
                >
                  <ListItemIcon>
                    <ConstructionRoundedIcon />
                  </ListItemIcon>
                  <ListItemText primary='Configure Shop' />
                </ListItemButton>
              </ListItem>
              :
              <></>
          }
          {
            this.state.loggedInUser.admin
              ?
              <ListItem key='manage' disablePadding>
                <ListItemButton
                  component={NavLink}
                  to='/manage'
                  selected={this.state.activeItem === 'manage'}
                  onClick={() => { this.setState({ activeItem: 'manage' }, () => { this.handleDrawerToggle(); }); }}
                >
                  <ListItemIcon>
                    <AdminPanelSettingsRoundedIcon />
                  </ListItemIcon>
                  <ListItemText primary='Site Administration' />
                </ListItemButton>
              </ListItem>
              :
              <></>
          }
          <ListItem />
          <Divider />
          <ListItem />
          <ListItem key='schedules' disablePadding>
            <ListItemButton
              component={NavLink}
              to='/schedules'
              selected={this.state.activeItem === 'schedules'}
              onClick={() => { this.setState({ activeItem: 'schedules' }, () => { this.handleDrawerToggle(); }); }}
            >
              <ListItemIcon>
                <AlarmRoundedIcon />
              </ListItemIcon>
              <ListItemText primary='Manage Subscriptions' />
            </ListItemButton>
          </ListItem>
          {
            this.state.loggedInUser.advisor
              ?
              <ListItem>
                <ListItemIcon>
                  <PhoneInTalkRoundedIcon />
                </ListItemIcon>
                <ListItemText primary="Allow Notifications" />
                <Switch
                  edge="end"
                  checked={this.state.notify}
                  onChange={(e, data) => { this.handleNotifyChange(data); }}
                />
              </ListItem>
              :
              <></>
          }
          <ListItem key='logout' disablePadding>
            <ListItemButton
              onClick={() => { this.props.auth0.logout({ client_id: process.env.REACT_APP_AUTH0CLIENTID, federated: true }) }}
            >
              <ListItemIcon>
                <LogoutRoundedIcon />
              </ListItemIcon>
              <ListItemText primaryTypographyProps={{ fontSize: '.75em' }} primary={this.props.auth0.user.email} />
            </ListItemButton>
          </ListItem>
          <ListItem />
          <Divider />
          <ListItem />
          <ListItem disablePadding>
            <ListItemButton
              component="a"
              href="https://changepointauto.zendesk.com/hc/en-us"
              target="_blank"
            >
              <ListItemIcon>
                <HelpRoundedIcon />
              </ListItemIcon>
              <ListItemText primary='Help Center' />
            </ListItemButton>
          </ListItem>
        </List >
      </>
    );
  }

  /////////////////////

  componentDidMount() {
    interval(() => {
      if ("Notification" in window) {
        if (Notification.permission !== "granted" && this.state.notify === true) {
          this.setState({ notify: false }, () => {
            toast.error("You've Denied Notifications", {
              position: 'bottom-left'
            });
          });
        }
      }
    }, 10000);

    interval(() => {
      if (this.props.auth0.isAuthenticated) { this.getNotify(); }
    }, 600000);

    socket.on('user', (msg) => {
      this.getLoggedInUser();
    });

    socket.on('companies', (msg) => {
      if (msg === this.state.selected_shop._id) {
        this.getLoggedInUser();
      }
    });

    socket.on('rc', (msg) => {
      this.customerPhoneCall(msg);
    });

    socket.on('settings', (msg) => {
      this.getNotify();
    });

    socket.on('connect', () => {
      if (this.props.auth0.isAuthenticated) {
        if (dayjs().diff(this.state.online, 'minute') >= 3) {
          window.location.reload();
        } else {
          this.getLoggedInUser();
        }
      }
    });

    interval(() => {
      isOnline().then((on) => {
        if (on) {
          this.setState({ online: dayjs() });
        }
      });
    }, 60000);

    if (window.location.pathname === '/customersearch') {
      this.setState({ activeItem: 'customersearch' });
    } else if (window.location.pathname === '/metrics') {
      this.setState({ activeItem: 'metrics' });
    } else if (window.location.pathname === '/manage') {
      this.setState({ activeItem: 'manage' });
    } else if (window.location.pathname === '/schedules') {
      this.setState({ activeItem: 'schedules' });
    } else if (window.location.pathname === '/pulse') {
      this.setState({ activeItem: 'pulse' });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.auth0.isAuthenticated && this.state.loggedInUser === null) {
      this.getLoggedInUser();
    }

    if ("Notification" in window) {
      if (Notification.permission === "granted" && this.state.notify === null) {
        this.setState({ notify: true });
      }
    }

    if (prevState.dashboards === null && this.state.dashboards !== null) {
      this.state.dashboards.forEach((dashboard, i) => {
        if (i === 0) {
          if (window.location.pathname === '/') {
            this.setState({ activeItem: dashboard.path, dbOpen: true });
          }
        } else {
          if (window.location.pathname === "/" + dashboard.path) {
            this.setState({ activeItem: dashboard.path, dbOpen: true });
          }
        }
      });
    }
  }

  /////////////////////

  render() {
    if (this.props.auth0.error) {
      this.props.auth0.logout({ client_id: process.env.REACT_APP_AUTH0CLIENTID, federated: true });
    }

    if (this.props.auth0.isLoading) {
      return (
        <Box
          component="main"
          sx={{ flexGrow: 1, p: 3, alignItems: 'center', textAlign: 'center' }}
        >
          <CircularProgress size={60} thickness={4} />
        </Box>
      );
    }

    if (this.props.auth0.isAuthenticated) {
      if (this.state.loggedInUser !== null && this.state.selected_shop !== null && this.state.dashboards !== null) {
        return (
          <Box sx={{ display: 'flex' }}>
            <Snackbar
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              open={this.state.errorNotify.status}
              onClose={() => { this.closeSnackBar(); }}
              key={'bottom' + 'center'}
              ClickAwayListenerProps={{ onClickAway: () => null }}
            >
              <MuiAlert elevation={6} variant="filled" onClose={() => { this.closeSnackBar(); }} severity="warning" sx={{ width: '100%' }}>
                {this.state.errorNotify.value}
              </MuiAlert>
            </Snackbar>
            <CssBaseline />
            <Router>
              <AppBar
                position="fixed"
                sx={{
                  display: { xs: 'block', md: 'none' },
                  backgroundColor: 'white',
                  color: 'black'
                }}
              >
                <Toolbar>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={() => { this.handleDrawerToggle(); }}
                    sx={{ mr: 2, display: { md: 'none' } }}
                  >
                    <MenuIcon />
                  </IconButton>
                  <Typography component='div' sx={{ alignItems: 'center', flexGrow: 1 }} >
                    <img src='images/cp_logo_long.png' height={'35px'} style={{ display: { xs: 'block', md: 'none' }, verticalAlign: 'middle' }} />
                  </Typography>
                </Toolbar>
              </AppBar>

              <Box
                component="nav"
                sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
                aria-label="menu options"
              >
                <Drawer
                  variant="temporary"
                  open={this.state.mobileOpen}
                  onClose={() => { this.handleDrawerToggle(); }}
                  ModalProps={{
                    keepMounted: true,
                  }}
                  sx={{
                    display: { xs: 'block', md: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                  }}
                  PaperProps={{ elevation: 5 }}
                  disableScrollLock={true}
                >
                  {this.displayDrawer()}
                </Drawer>
                <Drawer
                  variant="permanent"
                  sx={{
                    display: { xs: 'none', md: 'block' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                  }}
                  PaperProps={{ elevation: 5 }}
                  open
                >
                  {this.displayDrawer()}
                </Drawer>
              </Box>
              <Box
                component="main"
                sx={{ flexGrow: 1, p: 3, width: { xs: '100%', md: `calc(100% - ${drawerWidth}px)` } }}
              >
                <Toolbar sx={{ display: { xs: 'block', md: 'none' } }} />
                <Dialog
                  fullScreen
                  open={this.state.modal}
                  onClose={() => { this.handleModal(false); }}
                  TransitionComponent={Transition}
                >
                  <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                      <IconButton
                        edge="start"
                        color="inherit"
                        onClick={() => { this.handleModal(false); }}
                        aria-label="close"
                      >
                        <CloseIcon />
                      </IconButton>
                      <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Customer Information
                      </Typography>
                    </Toolbar>
                  </AppBar>
                  {this.showCustomer()}
                </Dialog>
                <Dialog
                  open={!this.state.loggedInUser.tos}
                  maxWidth={'lg'}
                  scroll={'paper'}
                  aria-labelledby="scroll-dialog-title"
                  aria-describedby="scroll-dialog-description"
                >
                  <DialogTitle id="scroll-dialog-title">Terms & Conditions</DialogTitle>
                  <DialogContent dividers>
                    <DialogContentText id="scroll-dialog-description">
                      <br /><b>Terms Of Service</b><br />
                      By signing up for the Changepoint Auto service (&ldquo;Service&rdquo;) you are agreeing to be bound by the following terms and conditions (&ldquo;Terms of Service&rdquo;). Any new features or tools which are added to the current Service shall be also subject to the Terms of Service. You can review the most current version of the Terms of Service at any time here. Changepoint Auto reserves the right to update and change the Terms of Service by posting updates and changes to the Changepoint Auto website.<br />
                      You are advised to check the Terms of Service from time to time for any updates or changes that may impact you.<br />
                      You must read, agree with and accept all of the terms and conditions contained or expressly referenced in these Terms of Service, including Changepoint Auto&rsquo;s Acceptable Use Policy (&ldquo;AUP&rdquo;) and Privacy Policy.<br />
                      <br /><b>Account Terms</b><br />
                      You must be 18 years or older to use the Changepoint Auto service.<br />
                      You must provide your full legal name, a valid email address, and any other information needed in order to complete the signup process.<br />
                      You are responsible for keeping your password secure. Changepoint Auto cannot and will not be liable for any loss or damage from your failure to maintain the security of your account and password. You may not use the Changepoint Auto service for any illegal or unauthorized purpose nor may you, in the use of the Service, violate any laws in your own location (including but not limited to copyright laws) as well as the laws of England and Wales. You are responsible for all activity and content (data, graphics, photos, links) that is uploaded under your Changepoint Auto account.<br />
                      You must not transmit any worms or viruses or any code of a destructive nature.<br />
                      A breach or violation of any of the Account Terms as determined in the sole discretion of Changepoint Auto will result in an immediate termination of your services.<br />
                      <br /><b>General Conditions</b><br />
                      We reserve the right to modify or terminate the Service for any reason, without notice at any time. We reserve the right to refuse service to anyone for any reason at any time.<br />
                      Your use of the Service is at your sole risk. The Service is provided on an &rdquo;as is&ldquo; and &rdquo;as available&ldquo; basis without any warranty or condition, express, implied or statutory.<br />
                      You understand that your Content (not including credit card information), may be transferred unencrypted and involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices. Credit Card information is always encrypted during transfer over networks.<br />
                      We may, but have no obligation to, remove Content and Accounts containing Content that we determine in our sole discretion are unlawful, offensive, threatening, libelous, defamatory, pornographic, obscene or otherwise objectionable or violates any party&rsquo;s intellectual property or these Terms of Service.<br />
                      <br />
                      Changepoint Auto does not warrant that the quality of any products, services, information, or other material purchased or obtained by you through the Service will meet your expectations, or that any errors in the Service will be corrected.<br />
                      You expressly understand and agree that Changepoint Auto shall not be liable for any direct, indirect, incidental, special, consequential or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses resulting from the use of or inability to use the service.<br />
                      In no event shall Changepoint Auto be liable for lost profits or any special, incidental or consequential damages arising out of or in connection with our site, our services or this agreement (however arising including negligence). You agree to indemnify and hold us and (as applicable) our subsidiaries, affiliates, Changepoint Auto partners, officers, directors, agents, and employees, harmless from any claim or demand, including reasonable legal fees, made by any third party due to or arising out of your breach of this Agreement or the documents it incorporates by reference, or your violation of any law or the rights of a third party.<br />
                      <br />
                      You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service, or access to the Service without the express written permission by Changepoint Auto.<br />
                      <br />
                      We do not claim any intellectual property rights over the material you provide to the Changepoint Auto service. All material you upload remains yours.<br />
                      By uploading images and item description content to ChangePoint Automotive Data Solutions , you agree to allow other internet users to view them and you agree to allow Changepoint Auto to display and store them and you agree that Changepoint Auto can, at any time, review all the content submitted by you to its Service.<br />
                      <br />
                      The failure of Changepoint Auto to exercise or enforce any right or provision of the Terms of Service shall not constitute a waiver of such right or provision. The Terms of Service constitute the entire agreement between you and Changepoint Auto and govern your use of Changepoint Auto, superseding any prior agreements between you and Changepoint Auto (including, but not limited to, any prior versions of the Terms of Service).<br />
                      <br />
                      Changepoint Auto does not pre-screen Content and it is in their sole discretion to refuse or remove any Content that is available via the Service. You may not modify, remove, hide or otherwise conceal the Changepoint Auto branding from any part of this service, without an active subscription to the appropriate plan. Any attempt to modify, remove, hide or otherwise conceal the Changepoint Auto branding without an active subscription to the appropriate plan may result in a suspension or termination of your account.<br />
                      Questions about the Terms of Service should be sent to info@changepnt.com .<br />
                      <br /><b>Changepoint Auto Rights</b><br />
                      We reserve the right to modify or terminate the Service for any reason, without notice at any time.<br />
                      We reserve the right to refuse service to anyone for any reason at any time.<br />
                      We may, but have no obligation to, remove Content and Accounts containing Content that we determine in our sole discretion are unlawful, offensive, threatening, libelous, defamatory, pornographic, obscene or otherwise objectionable or violates any party&rsquo;s intellectual property or these Terms of Service.<br />
                      <br />
                      Verbal or written abuse of any kind (including threats of abuse or retribution) of any Changepoint Auto customer, Changepoint Auto employee, member, or officer will result in immediate account termination.<br />
                      Changepoint Auto does not pre-screen Content and it is in their sole discretion to refuse or remove any Content that is available via the Service.<br />
                      <br /><b>Fees</b><br />
                      We reserve the right to change our pricing schedule from time to time. Changes to that schedule are effective after we provide you with at least fourteen days' notice by posting the changes on the Site.<br />
                      You are responsible for paying all fees and applicable taxes associated with our Site and Service (the &quot;Taxes&quot;).<br />
                      Changepoint Auto shall not be liable to you or to any third party for any modification, price change, suspension or discontinuance of the Service.<br />
                      All taxes and fees due in your location are to be paid by yourself and not Changepoint Auto, Changepoint Auto accepts no liability for any fees or taxes that you are liable for.<br />
                      Changepoint Auto does not provide refunds.<br />
                      <br /><b>Limitation Of Liability</b><br />
                      You expressly understand and agree that Changepoint Auto shall not be liable for any direct, indirect, incidental, special, consequential or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses resulting from the use of or inability to use the service.<br />
                      In no event shall Changepoint Auto or our suppliers be liable for lost profits or any special, incidental or consequential damages arising out of or in connection with our site, our services or this agreement (however arising including negligence). You agree to indemnify and hold us and (as applicable) our subsidiaries, affiliates, Changepoint Auto partners, officers, directors, agents, and employees, harmless from any claim or demand, including reasonable legal fees, made by any third party due to or arising out of your breach of this Agreement or the documents it incorporates by reference, or your violation of any law or the rights of a third party.<br />
                      Your use of the Service is at your sole risk. The Service is provided on an &rdquo;as is&ldquo; and &rdquo;as available&ldquo; basis without any warranty or condition, express, implied or statutory.<br />
                      Changepoint Auto does not warrant that the service will be uninterrupted, timely, secure, or error-free.<br />
                      Changepoint Auto does not warrant that the results that may be obtained from the use of the service will be accurate or reliable.<br />
                      Changepoint Auto does not warrant that the quality of any products, services, information, or other material purchased or obtained by you through the Service will meet your expectations, or that any errors in the Service will be corrected.<br />
                      <br /><b>Waiver And Complete Agreement</b><br />
                      The failure of Changepoint Auto to exercise or enforce any right or provision of the Terms of Service shall not constitute a waiver of such right or provision. The Terms of Service constitutes the entire agreement between you and Changepoint Auto and govern your use of the Service, superseding any prior agreements between you and Changepoint Auto (including, but not limited to, any prior versions of the Terms of Service).<br />
                      Intellectual Property And Customer Content<br />
                      We do not claim any intellectual property rights over the material you provide to the Changepoint Auto service. All material you upload remains yours. You can cancel your account at any time. This will remove all content you have stored on the Service.<br />
                      By uploading images and item description content to ChangePoint Automotive Data Solutions , at any time, review all the content submitted by you to its Service.<br />
                      <br />
                      <br /><b>Cancellation And Termination</b><br />
                      You may cancel your account at any time via your dashboard, or by emailing info@changepnt.com. Once your account is canceled and your data connection stopped by you, all of your Content will be immediately deleted from the Service. If you do not stop the data connection in your shop management system we are not able to delete your data. Since deletion of all data is final please be sure that you do in fact want to cancel your account before doing so.<br />
                      We reserve the right to modify or terminate the Changepoint Auto service for any reason, without notice at any time.<br />
                      Fraud: Without limiting any other remedies, Changepoint Auto may suspend or terminate your account if we suspect that you (by conviction, settlement, insurance or escrow investigation, or otherwise) have engaged in fraudulent activity in connection with the Site.<br />
                      <br /><b>Data Security</b><br />
                      You are fully responsible for the security of data on your website or otherwise in your possession. You agree to comply with all applicable local laws and regulations in connection with your collection, security and dissemination of any personal, financial, payment card, or transaction information (defined as &ldquo;Data&rdquo;) on your website. You agree that at all times you shall be compliant with the Payment Card Industry Data Security Standards (PCI-DSS) and the Payment Application Data Security Standards (PA-DSS), as applicable. The steps you will need to take to comply with PCI-DSS and PA-DSS when using Changepoint Auto and Stripe will vary based on your implementation. Information on the PCI DSS can be found on the PCI Council&rsquo;s website. It is your responsibility to comply with these standards.<br />
                      <br /><b>Legal Disputes</b><br />
                      If a dispute arises between you and Changepoint Auto, our goal is to provide you with a neutral and cost effective means of resolving the dispute quickly. Accordingly, you and Changepoint Auto agree that we will resolve any claim or controversy at law or equity that arises out of this Agreement or our services (a &quot;Claim&quot;) in accordance with one of the subsections below or as we and you otherwise agree in writing. Before resorting to these alternatives, we strongly encourage you to first contact us directly at info@changepnt.com to seek a resolution. We will consider reasonable requests to resolve the dispute through alternative dispute resolution procedures, such as mediation or arbitration, as alternatives to litigation.<br />
                      The Agreement will be governed by and construed in accordance with the laws of South Carolina.<br />
                      <br /><b>Unlawful Content</b><br />
                      You will ensure that the Customer Works does not infringe any applicable laws, regulations or third party rights (&ldquo;Unlawful Content&ldquo;).<br />
                      You will indemnify and will keep indemnified Changepoint Auto against all damages, losses and expenses (including legal expenses) arising as a result of any claim that the Customer Works constitute Unlawful Content, or any legal proceedings relating to such a claim.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => { this.tosAgree(); }}>I Agree</Button>
                  </DialogActions>
                </Dialog>

                <Routes>
                  {this.displayRoutes()}
                  {
                    this.state.loggedInUser.advisor
                      ?
                      <Route exact path="/customersearch" element={<CustomerSearch loggedInUser={this.state.loggedInUser} shop={this.state.selected_shop} socket={socket} />} />
                      :
                      <></>
                  }
                  {
                    this.state.loggedInUser.roles.includes('owner')
                      ?
                      <Route exact path="/metrics" element={<Metrics loggedInUser={this.state.loggedInUser} shop={this.state.selected_shop} socket={socket} />} />
                      :
                      <></>
                  }
                  {
                    this.state.loggedInUser.admin === true
                      ?
                      <Route exact path="/manage" element={<Manage loggedInUser={this.state.loggedInUser} socket={socket} />} />
                      :
                      <></>
                  }
                  <Route exact path="/schedules" element={<UserSchedules />} />
                  <Route exact path="/pulse" element={
                    <Pulse
                      shop={this.state.selected_shop}
                      dashboard={'pulse'}
                      socket={socket}
                      multishop={this.state.multishop ? this.state.loggedInUser.companies : null}
                    />
                  }
                  />
                </Routes>
              </Box>
            </Router>

            <ToastContainer position='top-right' autoClose={3000} />
          </Box>
        );
      } else {
        return (
          <Box
            component="main"
            sx={{ flexGrow: 1, p: 3, alignItems: 'center', textAlign: 'center' }}
          >
            <CircularProgress size={60} thickness={4} />
          </Box>
        );
      }
    } else {
      return (
        <>
          <Landing />
          <ToastContainer position='top-right' autoClose={3000} />
        </>
      );
    }
  }
}

export default withAuth0(App);
